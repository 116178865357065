import { FloatingPortal, autoUpdate, limitShift, offset, shift, useDismiss, useFloating, useInteractions, useMergeRefs } from '@floating-ui/react'
import { determineDocumentPathSyncWithParams } from '/machinery/determineDocumentPathSyncWithParams'
import { animated, useSpring } from 'react-spring'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { ImageCropped } from '/features/buildingBlocks/Image'
import { useElementSize } from '@kaliber/use-element-size'
import { useMediaQuery } from '@kaliber/use-media-query'
import { CherryBlossomVisual } from '/features/pageOnly/CherryBlossomVisual'
import { floatingContentId, useFloatingContentRoot } from '/machinery/FloatingContent'
import { PortableText } from '@portabletext/react'
import { routeMap } from '/routeMap'
import { refToDataXLink, trackInteraction } from '/machinery/tracking/pushToDataLayer'

import cssStyleContext from '/cssGlobal/style-context.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './LittleActsOfKindness.css'

const iconSizeCssVariable = parseFloat(styles.iconSize)
const gapCssVariable = parseFloat(styles.gap)
const albumArtCssVariable = parseFloat(styles.albumArtSize)
const albumArtMaxSizeCssVariable = parseFloat(styles.albumArtMaxSize)
const padding = 10

export function LittleActsOfKindnessAnchor({ littleActsOfKindness, layoutClassName = undefined }) {
  const { isOpen, setIsOpen, referenceProps, floatingProps } = useFloatingActOfKindness({ title: littleActsOfKindness.title })
  const root = useFloatingContentRoot({ id: floatingContentId })

  return  (
    <div className={cx(styles.componentAnchor, layoutClassName)}>
      <div className={styles.referenceElement} {...referenceProps} />

      {root && (
        <FloatingPortal id='litle-acts-of-kindness-root' {...{ root }}>
          <LittleActsOfKindnessRef
            onOpenChange={() => handleClick(littleActsOfKindness.title)}
            {...{ isOpen, littleActsOfKindness }}
            {...floatingProps}
          />
        </FloatingPortal>
      )}
    </div>
  )

  function handleClick( title ) {
    setIsOpen(!isOpen)
    trackInteraction({
      type: 'click',
      title: title ?? 'little-acts-of-kindness',
      action: 'popup activated',
    })
  }
}

const LittleActsOfKindnessRef = React.forwardRef(LittleActsOfKindness)
export function LittleActsOfKindness({ style, isOpen, onOpenChange, littleActsOfKindness, ...rest }, ref) {
  const [isHovering, setIsHovering] = React.useState(false)
  const { ref: innerContentRef, size: innerContentSize } = useElementSize()
  const { ref: containerRef, size } = useElementSize()
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false
  const refs = useMergeRefs([ref, containerRef])

  const hasContent = littleActsOfKindness.content
  const hasImage = littleActsOfKindness.image
  const hasLink = littleActsOfKindness.links?.length

  const link = littleActsOfKindness.links?.[0] ?? null

  const { height } = useSpring({
    height: isOpen
      ? iconSizeCssVariable + innerContentSize.height + (2 * gapCssVariable) + padding
      : iconSizeCssVariable
  })

  return (
    <animated.div
      ref={refs}
      className={cx(styles.component, isHovering && styles.isHovering, isOpen && styles.isOpen)}
      data-style-context={cssStyleContext.contextWhite}
      style={{ ...style, height }}
      {...rest}
    >
      <Icon
        onClick={onOpenChange}
        onMouseEnter={() => !isMobile && setIsHovering(true)}
        onMouseLeave={() => !isMobile && setIsHovering(false)}
        layoutClassName={styles.iconLayout}
      />

      <Text
        visible={isOpen || isHovering}
        layoutClassName={styles.textLayout}
        {...{ isHovering, isOpen }}
      >
        {littleActsOfKindness.title}
      </Text>

      <Content visible={isOpen} layoutClassName={styles.contentLayout}>
        <div ref={innerContentRef} className={styles.contentInner}>
          <div className={styles.contentInnerText}>
            {hasContent && (
              <PortableText value={littleActsOfKindness.content} />
            )}
          </div>

          {hasLink && (
            <ButtonGhost
              dataX={refToDataXLink(link.ref)}
              href={link.href ?? determineDocumentPathSyncWithParams({ document: link.ref, routeMap, params: link.params })}
            >
              {link.label}
            </ButtonGhost>
          )}

          {(isMobile && hasImage) && (
            <Media
              visible={isOpen}
              layoutClassName={styles.mediaLayout}
              image={littleActsOfKindness.image}
            />
          )}
        </div>
      </Content>

      {(!isMobile && hasImage) && (
        <Media
          visible={isOpen}
          layoutClassName={styles.mediaLayout}
          image={littleActsOfKindness.image}
        />
      )}

      <Background
        width={size.width}
        height={innerContentSize.height}
        layoutClassName={styles.backgroundLayout}
        {...{ isHovering, isOpen }}
      />
    </animated.div>
  )
}

function Icon({ onClick, onMouseEnter, onMouseLeave, layoutClassName }) {
  return (
    <button
      data-style-context={cssStyleContext.contextBlack}
      className={cx(styles.componentIcon, layoutClassName)}
      {...{ onClick, onMouseEnter, onMouseLeave }}
    >
      <CherryBlossomVisual />
    </button>
  )
}

function Text({ children, visible, layoutClassName }) {
  const style = useSpring({
    opacity: visible ? 1 : 0,
    x: visible ? 0 : 5
  })

  return (
    <animated.div
      className={cx(styles.componentText, layoutClassName)}
      {...{ style }}
    >
      {children}
    </animated.div>
  )
}

function Content({ children, visible, layoutClassName }) {
  const style = useSpring({
    y: visible ? 0 : -5,
    opacity: visible ? 1 : 0,
    delay: visible ? 100 : 0
  })

  return (
    <animated.div
      className={cx(styles.componentContent, visible && styles.isVisible, layoutClassName)}
      {...{ style }}
    >
      {children}
    </animated.div>
  )
}

function Media({ image, visible, layoutClassName }) {
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false
  const style = useSpring({
    transformOrigin: isMobile ? 'left center' : 'top left',
    opacity: visible ? 1 : 0,
    delay: visible ? 300 : 0,
    y: (visible && !isMobile) ? 0 : -10,
    x: visible ? 0 : -5
  })

  return (
    <animated.div className={cx(styles.componentMedia, layoutClassName)} {...{ style }}>
      <ImageCropped sizes='var(--album-art-size)' aspectRatio={1 / 1} layoutClassName={styles.mediaElementLayout} {...{ image }} />
    </animated.div>
  )
}

function Background({ width: measuredWidth, height: innerContentHeight, isHovering, isOpen, layoutClassName }) {
  const fullElementHeight = (innerContentHeight + iconSizeCssVariable)

  const minimalHeight = fullElementHeight > albumArtCssVariable
    ? iconSizeCssVariable + innerContentHeight
    : albumArtCssVariable

  const measuredWidthMinusPaddings = measuredWidth - (gapCssVariable * 2)
  const width = (isHovering && !isOpen)
    ? measuredWidthMinusPaddings - albumArtMaxSizeCssVariable
    : isOpen
      ? measuredWidthMinusPaddings
      : iconSizeCssVariable

  const style = useSpring({
    from: { width: iconSizeCssVariable  },
    width,
    height: isOpen ? minimalHeight + (gapCssVariable * 2) : iconSizeCssVariable,
    x: (isHovering || isOpen) ? (iconSizeCssVariable / 2) : 0,
  })

  return (
    <animated.div
      className={cx(styles.componentBackground, layoutClassName)}
      {...{ style }}
    />
  )
}

function useFloatingActOfKindness({ title }) {
  const [isOpen, setIsOpen] = React.useState(false)

  const { context, refs: { setReference, setFloating }, floatingStyles: style } = useFloating({
    middleware: [
      shift({ limiter:
        limitShift({
          offset: ({ rects }) => ({
            mainAxis: rects.reference.height,
            crossAxis: rects.floating.width,
          })
        })
      }),
      offset(padding),
    ],
    whileElementsMounted: autoUpdate,
    onOpenChange: setIsOpen,
    placement: 'right-start',
    open: true
  })

  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
  ])

  return {
    isOpen,
    setIsOpen,
    referenceProps: {
      ref: setReference,
      ...getReferenceProps()
    },
    floatingProps: {
      style,
      ref: setFloating,
      ...getFloatingProps()
    }
  }
}
