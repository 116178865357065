export const floatingContentId = 'floating-content-root'
export const floatingFiltersId = 'floating-filters-root'

export function useFloatingContentRoot({ id }) {
  const [floatingContentElement, setFloatingContentElement] = React.useState(null)

  React.useEffect(
    () => {
      const element = document.getElementById(id)
      setFloatingContentElement(element)
    },
    [id]
  )

  return floatingContentElement
}
